<script>
    export default {
        setup() {
            const layout = useLayoutStore();
            const slider = useSliderStore();
            const helpers = useHelperStore();
            const storage = useResponseStore();
            const auth = useAuthStore();
            const module = useModuleStore();
            const config = useConfigurationStore();
            return { layout, slider, helpers, storage, auth, module, config };
        },
        computed: {
            ...mapGetters(useAuthStore, ['hasSession']),
            ...mapGetters(useResponseStore, ['htmlError', 'errors']),
            year() {
                return new Date().getFullYear();
            },
            modalType() {
                if (this.documentation?.attachment?.fileType == 'image') {
                    return 'image';
                } else if (this.documentation?.attachment?.fileExt == 'pdf') {
                    return 'iframe';
                } else {
                    return null;
                }
            }
        },
        created() {
            if (this.hasSession) {
                this.$router.replace('/dashboard');
            }
        },
        mounted() {
            this.slider
                .fetchAll({
                    skipPagination: true,
                    active: true
                })
                .then(async () => {
                    await this.fetchImageBackgroundTones();
                });
            if (this.module.check('document')) this.auth.getDocumentation();
        },
        data() {
            return {
                data: {
                    email: '',
                    password: '',
                    remember_me: false
                },
                documentation: null
            };
        },
        methods: {
            setLocale(payload) {
                this.$i18n.locale = payload;
                this.storage.setStorage('locale', payload);
                if (document.getElementById('dropdown-lang')) UIKit.dropdown('#dropdown-lang').hide(false);
            },
            showAttachment(media) {
                this.documentation = media;
                if (media?.attachment.fileType == 'image') {
                    UIKit.modal('#modal-attachment').show();
                } else if (media?.attachment.fileExt == 'pdf') {
                    UIKit.modal('#modal-attachment').show();
                } else {
                    window.open(media?.attachment.mediaUrl, '_blank');
                }
            },
            async fetchImageBackgroundTones() {
                for (const data of this.slider.list) {
                    try {
                        const backgroundTone = await this.getImageBackgroundTone(data.imageUrl);
                        data.backgroundTone = backgroundTone;
                    } catch (error) {
                        console.error('Error fetching background tone:', error);
                    }
                }
            },

            async getBackgroundTone(imageElement) {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = imageElement.width;
                canvas.height = imageElement.height;

                ctx.drawImage(imageElement, 0, 0, canvas.width, canvas.height);

                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const data = imageData.data;

                let totalRed = 0;
                let totalGreen = 0;
                let totalBlue = 0;

                for (let i = 0; i < data.length; i += 4) {
                    totalRed += data[i];
                    totalGreen += data[i + 1];
                    totalBlue += data[i + 2];
                }

                const averageRed = Math.round(totalRed / (data.length / 4));
                const averageGreen = Math.round(totalGreen / (data.length / 4));
                const averageBlue = Math.round(totalBlue / (data.length / 4));

                return `rgb(${averageRed}, ${averageGreen}, ${averageBlue})`;
            },

            async getImageBackgroundTone(imageUrl) {
                const image = new Image();
                image.crossOrigin = 'anonymous';

                return new Promise((resolve, reject) => {
                    image.onload = async () => {
                        try {
                            const backgroundTone = await this.getBackgroundTone(image);
                            resolve(backgroundTone);
                        } catch (error) {
                            console.error('Error getting background tone:', error);
                            reject(error);
                        }
                    };

                    image.onerror = (error) => {
                        console.error('Error loading image:', error);
                        reject(error);
                    };

                    image.src = imageUrl;
                });
            }
        }
    };
</script>

<template>
    <div class="uk-section uk-padding-remove uk-background-default">
        <div uk-height-viewport="offset-top: true; offset-bottom: true" class="uk-grid-collapse uk-flex-center" uk-grid>
            <div v-if="slider.list?.length > 0" class="uk-width-1-2@m uk-width-2-3@l uk-inline uk-visible@m">
                <div>
                    <div
                        class="uk-position-relative uk-visible-toggle uk-light"
                        tabindex="-1"
                        uk-slideshow="animation: pull; autoplay: true"
                    >
                        <ul class="uk-slideshow-items" uk-height-viewport="offset-top: true; offset-bottom: true">
                            <li
                                v-for="(data, index) in slider.list"
                                :key="index"
                                :style="{
                                    'background-color': data.backgroundTone
                                }"
                            >
                                <img
                                    :src="data.imageUrl"
                                    :alt="data.title"
                                    class="uk-position-center"
                                    style="width: 100%"
                                />
                                <div
                                    v-if="!data.hideTitle"
                                    :style="{
                                        'background-color': data.backgroundTone
                                    }"
                                    class="uk-overlay uk-overlay-primary uk-position-bottom uk-text-center uk-transition-slide-bottom"
                                >
                                    <h3 class="uk-margin-remov">
                                        {{ data.title }}
                                    </h3>
                                    <p class="uk-margin-remove-top">
                                        {{ data.description }}
                                    </p>
                                </div>
                            </li>
                        </ul>

                        <a
                            class="uk-position-center-left uk-position-small uk-hidden-hover"
                            href="#"
                            uk-slidenav-previous
                            uk-slideshow-item="previous"
                        ></a>
                        <a
                            class="uk-position-center-right uk-position-small uk-hidden-hover"
                            href="#"
                            uk-slidenav-next
                            uk-slideshow-item="next"
                        ></a>
                    </div>
                </div>
            </div>
            <div
                class="uk-width-expand uk-width-1-2@m uk-width-1-3@l uk-text-center uk-flex uk-flex-center uk-flex-middle"
            >
                <div class="uk-position-top-right uk-padding">
                    <button class="uk-button uk-button-default uk-flex uk-flex-middle uk-padding-remove">
                        <div class="initial-name bg-color-x uk-margin-small-left uk-text-bold">
                            {{ $i18n.locale.toUpperCase() }}
                        </div>
                    </button>
                    <div id="dropdown-lang" uk-dropdown="mode: click;">
                        <button
                            v-for="(locale, key) in $i18n.availableLocales"
                            @click="setLocale(locale)"
                            v-bind:key="key"
                            class="uk-margin-auto-vertical uk-button uk-button-default uk-text-left uk-width-1-1 uk-flex uk-flex-middle"
                            :class="locale === $i18n.locale ? 'uk-text-bold' : ''"
                        >
                            <span class="navigation-side-label">
                                {{ locale.toUpperCase() }} -
                                {{ helpers.getLocaleName(locale) }}
                            </span>
                        </button>
                    </div>
                </div>
                <div style="background-color: #fff !important">
                    <div class="uk-width-1-1 uk-flex uk-flex-center uk-padding uk-padding-remove-bottom uk-margin-top">
                        <img
                            class="uk-text-center uk-width-small"
                            src="/images/logo-light.png"
                            uk-img="loading: eager;"
                            :alt="layout.appName"
                        />
                    </div>
                    <div class="uk-card uk-card-default uk-padding-small">
                        <div class="uk-padding">
                            <RouterView />
                        </div>
                    </div>
                    <div
                        v-if="auth.documentations?.length > 0"
                        class="uk-width-1-1 uk-flex uk-flex-center uk-margin-small-bottom"
                        style="margin-top: 20px"
                    >
                        <button
                            uk-toggle="target: #modal-documentation"
                            class="uk-button uk-button-small uk-button-default uk-text-small uk-text-primary"
                        >
                            <LicenseIcon />
                            {{ $t('Documentation') }}
                        </button>
                        <div id="modal-documentation" uk-modal>
                            <div class="uk-modal-dialog uk-modal-body">
                                <h2 class="uk-modal-title">
                                    {{ $t('Documentation') }}
                                </h2>
                                <div class="uk-overflow-auto">
                                    <table
                                        class="uk-table uk-table-hover uk-table-small uk-table-top uk-table-divider uk-table-striped uk-table-middle"
                                    >
                                        <thead>
                                            <tr>
                                                <th>{{ $t('Name') }}</th>
                                                <th class="uk-table-shrink uk-text-nowrap">
                                                    {{ $t('Release Date') }}
                                                </th>
                                                <th class="uk-table-shrink uk-text-nowrap">
                                                    {{ $t('Action') }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data, index) in auth.documentations" :key="index">
                                                <td class="uk-table-expand uk-text-nowrap">
                                                    {{ data.name }}
                                                </td>
                                                <td class="uk-table-shrink uk-text-nowrap">
                                                    {{ data.createdAt }}
                                                </td>
                                                <td class="uk-table-shrink uk-text-nowrap">
                                                    <button
                                                        @click="showAttachment(data)"
                                                        class="uk-button uk-button-primary uk-button-small"
                                                    >
                                                        <DownloadIcon />
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p class="uk-text-right">
                                    <button class="uk-button uk-button-primary uk-modal-close" type="button">
                                        {{ $t('Close') }}
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-1 uk-flex uk-flex-center">
                        <p class="uk-text-small uk-text-center">
                            {{ config.get('app.name') }}
                            <br />
                            {{ year }} © All right reserved.
                            <br />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="modal-attachment" class="uk-modal-container" uk-modal="stack:true;">
        <div class="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-header">
                <h2 v-if="documentation" class="uk-modal-title">
                    {{ documentation.name }}
                </h2>
            </div>
            <div v-if="documentation?.attachment" class="uk-modal-body uk-padding-remove uk-flex uk-flex-center">
                <img
                    v-if="modalType == 'image'"
                    :data-src="documentation?.attachment?.mediaUrl"
                    uk-img
                    alt="attachment"
                    class="uk-width-auto"
                />
                <iframe
                    class="uk-width-1-1 uk-height-large"
                    v-else
                    :src="documentation?.attachment?.mediaUrl"
                    frameborder="0"
                    title="attachment"
                />
            </div>
            <div v-if="documentation?.attachment?.mediaDownloadId" class="uk-modal-footer">
                <a
                    :href="documentation?.attachment?.mediaDownloadId"
                    target="_blank"
                    class="uk-button uk-button-primary uk-button-small uk-float-right"
                >
                    <DownloadIcon />
                    Download
                </a>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .login-grid {
        height: 100vh;
        overflow-y: auto;
    }
</style>
